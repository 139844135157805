.section {
  &.suppliers {
    .section__top-bar {
      .btn--big {
        padding: 1.15rem;
      }
    }
  }
}

//Start media
@media (max-width: 768px) {
  .section {
    &.suppliers {
      margin-bottom: 16px;

      .section {
        &__top-bar {
          .btn--yellow {
            position: fixed;
            min-width: auto;
            width: 48px;
            height: 48px;
            overflow: hidden;
            display: block;
            border-radius: 50%;
            right: 32px;
            bottom: 32px;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.25192 8.53638C8.25192 8.41065 8.35385 8.30873 8.47957 8.30873H12.6846C13.4111 8.30873 14 7.71978 14 6.99329C14 6.26679 13.4111 5.67785 12.6846 5.67785H8.47904C8.35361 5.67785 8.25192 5.57617 8.25192 5.45074V1.25865C8.25192 0.563517 7.6884 0 6.99327 0C6.29813 0 5.73462 0.563518 5.73462 1.25865V5.45074C5.73462 5.57617 5.63293 5.67785 5.5075 5.67785H1.31543C0.58894 5.67785 0 6.26679 0 6.99329C0 7.71978 0.588941 8.30873 1.31544 8.30873H5.50696C5.63269 8.30873 5.73462 8.41065 5.73462 8.53638V12.7413C5.73462 13.4365 6.29813 14 6.99327 14C7.6884 14 8.25192 13.4365 8.25192 12.7413V8.53638Z' fill='black'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            text-indent: 48px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
//End media
