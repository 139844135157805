.section {
  &.profile {
    padding-top: 3.42rem;

    .profile {
      &__sidebar {
        flex-basis: 200px;
        padding-right: 75px;

        .avatar {
          position: relative;
          margin-bottom: 2.52rem;

          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .btn {
            position: absolute;
            top: 0;
            left: calc(100px - 32px);
            background-color: #fff;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease-in-out;
            box-shadow: 1px 2px 32px rgba(0, 0, 0, 0.1);

            svg {
              transition: transform 0.3s ease-in-out;
            }

            &:hover {
              svg {
                transform: scale(1.2);
              }
            }
          }

          &:hover {
            .btn {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }

        .instruction {
          font-size: 0.71rem;

          .title {
            font-size: 0.86rem;
            margin-bottom: 1.14rem;
            text-transform: uppercase;
            line-height: 1.4;
          }

          p {
            margin-bottom: 1.14rem;
            color: rgba(0, 0, 0, 0.5);
          }

          a {
            text-decoration: none;
          }
        }
      }

      &__detail {
        flex: 1;

        .section__top-bar {
          padding: 2.42rem 0;
          align-items: flex-start;

          .btn--yellow {
            min-width: 145px;
            margin-top: -0.5rem;
          }
        }

        .title {
          margin-bottom: 3.42rem;
        }

        &--editing {
          .profile__detail-item {
            margin-bottom: 2.28rem;

            > div {
              &:first-child {
                margin-bottom: 1.14rem;
              }
            }
          }
        }
      }

      &__detail-item {
        margin-bottom: 3.42rem;

        &--full-width {
          grid-column: 1/3;
        }

        > div {
          &:first-child {
            text-transform: uppercase;
            font-size: 0.86rem;
            margin-bottom: 1.7rem;
          }
        }
      }

      &__detail-group {
        margin-bottom: 3.42rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2.14rem;
        max-width: 770px;

        .title {
          grid-column: 1/3;
        }

        &--full-width {
          grid-template-columns: 2fr;
        }

        + .profile__detail-group--full-width {
          margin-top: -3.48rem;
        }
      }

      &__row {
        display: flex;
      }
    }
  }
}

//Start media
@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .section {
    &.profile {
      .profile {
        &__row {
          flex-direction: column;
        }

        &__sidebar {
          max-width: 190px;
          margin: 0 auto;
          padding: 0;
          text-align: center;
          flex-basis: auto;

          .avatar {
            margin-bottom: 0;

            .btn {
              opacity: 1;
            }
          }

          .instruction {
            display: none;
          }
        }
      }
    }
  }
}
//End media
