.header {
  padding: 22px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #ffffff;

  &__logo {
    line-height: 0;
    margin-top: 4px;
  }
}

.navigation {
  .navigation__item {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 12px 24px 12px 24px;
    cursor: pointer;

    &::before {
      right: 24px;
    }

    &:hover {
      color: rgba(#000000, 0.8);
    }
  }
}

.desktop-menu {
  display: flex;
  align-items: center;

  & > div,
  & > a {
    margin-left: 32px;
  }

  .input.icon {
    &::before {
      left: 16px;
    }

    input {
      padding-left: 40px;
      padding-top: 0.95rem;
      padding-bottom: 0.85rem;
    }
  }
}

.mobile-menu {
  position: fixed;
  transform: translateY(-101%);
  right: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 32px 15px 32px;
  font-size: 1.4rem;
  opacity: 0;
  pointer-events: none;
  height: calc(100vh - 80px);
  overflow-y: scroll;

  &.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: unset;
  }

  .form {
    margin-bottom: 12px;
  }

  .navigation {
    ul {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      li {
        margin: 20px 0;
      }
    }
  }

  a,
  .navigation__item {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 12px 24px 12px 0;
    cursor: pointer;
  }

  .drop-down,
  .badge {
    margin-top: 20px;
  }

  .badge {
    display: block;
  }

  .badge--label .badge__counter {
    right: 0;
  }
}

select.locale-switcher {
  background: transparent;
  border: none;
  border-bottom: 2px solid #f3cd30;
  border-radius: 0;
  font-size: 14px;
  padding: 7px 2px;
  cursor: pointer;
}

.locale-switcher {
  &--mobile {
    justify-content: center;

    li {
      padding: 20px !important;
      margin: 0 !important;
    }
  }
}

.beta-version {
  font-size: 11px;
  line-height: 15px;
  padding: 5px 0;
  margin: -22px 0 22px;
  text-align: center;
  background: var(--yellow-color);
  font-weight: 500;

  a {
    color: #000000;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .header {
    padding: 24px 0;
  }

  .beta-version {
    bottom: -20px;
  }
}

@media (max-width: 576px) {
}

@media (min-width: 768px) {
  .mobile-menu {
    transform: translateY(-100%);
  }
}
