//Start vars
:root {
  --yellow-color: #f3cd29;
}

//End vars

//Start fonts
@font-face {
  font-family: 'Manrope3';
  src: url('./assets/fonts/Manrope3-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope3';
  src: url('./assets/fonts/Manrope3-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope3';
  src: url('./assets/fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope3';
  src: url('./assets/fonts/Manrope3-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope3';
  src: url('./assets/fonts/Manrope3-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

// @font-face {
//   font-family: "SF-Compact-Text";
//   src: url("https://www.fontify.me/cm/bc325006419a7c5afa1e7cdab635d4ac/SF-Compact-Text-Regular.woff")
//     format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "SF-Compact-Text";
//   src: url("https://www.fontify.me/cm/e5ea205cdfa60018335ee3db6d585197/SF-Compact-Text-Medium.woff")
//     format("woff");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "SF-Compact-Text";
//   src: url("https://www.fontify.me/cm/c61fba03e48c28001e90eba4b87ca238/SF-Compact-Text-Sremibold.woff")
//     format("woff");
//   font-weight: 600;
//   font-style: normal;
// }
//End fonts

//Start default style
::selection {
  background-color: var(--yellow-color);
  color: #000;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
  line-height: 1.65;
  font-weight: 500;
}

body {
  font-family: 'Manrope3', 'SF-Compact-Text', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  width: 100%;
  overflow-x: hidden;
  color: #000;
  background-color: #fff;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s ease-out;
  color: var(--yellow-color);

  &:hover {
    text-decoration: none;
  }

  &:visited {
    opacity: 0.9;
  }
}

button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

.delete {
  color: red;
}

b,
strong {
  font-weight: bold;
}

hr {
  margin: 32px 0;
  outline: none;
  border: none;
  height: 2px;
  background-color: rgba(#000, 0.05);
}

small {
  color: rgba(#000, 0.5);
  font-size: 1rem;
}

//End default style

::placeholder {
  color: rgba(#000, 0.5);
  font-weight: 500;
  font-family: 'Manrope3', 'SF-Compact-Text', 'Roboto', sans-serif;
  font-size: 1rem;
}

.form {
  input {
    line-height: 1rem;
    -webkit-appearance: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button,
  select {
    // appearance: none;
    // margin: 0;
    -webkit-appearance: none;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOSA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS4yNzMyNCAwQzAuNDAzMTMxIDAgLTAuMDUxODcwNSAxLjAzNDMyIDAuNTM2MDgxIDEuNjc1NzJMMy43NjI4NSA1LjE5NTgzQzQuMTU5MTggNS42MjgyIDQuODQwODIgNS42MjgyIDUuMjM3MTUgNS4xOTU4M0w4LjQ2MzkyIDEuNjc1NzNDOS4wNTE4NyAxLjAzNDMyIDguNTk2ODcgMCA3LjcyNjc2IDBIMS4yNzMyNFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    cursor: pointer;
  }

  input[disabled],
  textarea[disabled],
  select[disabled],
  input[type=file][disabled] + label {
    background-color: transparent;
    cursor: not-allowed;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-appearance: none;
    border: 2px solid rgba(#000000, 0.05);
    -webkit-text-fill-color: #000;
    box-shadow: 0 0 0px 1000px #fff inset;
  }

  .select-wrapper {
    width: 100%;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.7rem;
    position: relative;

    &__job-type-name {
      display: flex;
      align-items: center;
    }
  }

  &__result {
    &--red {
      color: red;
    }

    &--green {
      color: green;
    }
  }

  .field-lang {
    color: var(--yellow-color);
    position: absolute;
    top: 18px;
    font-size: 13px;
    right: 1rem;
    line-height: 13px;
  }
}

.question-mark {
  position: relative;

  &__icon {
    min-width: 15px;
    display: block;
    height: 15px;
    border-radius: 100px;
    border: 1px solid #f3cd29;
    font-size: 11px;
    color: #f3cd29;
    text-align: center;
    vertical-align: middle;
    margin-left: 6px;
    line-height: 14px;
    cursor: pointer;

    &:hover + .question-mark__description {
      opacity: 1;
      z-index: 1;
    }
  }

  &__description {
    opacity: 0;
    position: absolute;
    transition: opacity .3s ease-in-out;
    z-index: -1;
    left: 20px;
    bottom: 10px;
    background: #fff;
    border-radius: 4px;
    padding: 5px 10px;
    min-width: 230px;
    max-width: 280px;
    box-shadow: 1px 2px 52px rgba(0, 0, 0, 0.10);
  }
}

.required {
  color: var(--yellow-color);
  font-weight: bold;
  position: relative;
  bottom: 3px;
  left: 1px;
}

//End form style

//Icon classes start
.icon {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background-position: center;
    z-index: 1;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &--left {
    padding-left: 18px;

    &::before {
      left: 0;
    }
  }

  &--right {
    padding-left: 18px;

    &::before {
      right: 0;
    }
  }

  &--download {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13H10V11.4706H0V13ZM10 4.58823H7.14286V0H2.85714V4.58823H0L5 9.94118L10 4.58823Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }

  &--three-dots {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }

  &--email {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.4 4.94173L7 8.29893L12.6 4.94173V4.94103L7 1.58383L1.4 4.94103V4.94173ZM14 4.94103V11.6568C14 12.3987 13.3732 13 12.6 13H1.4C0.626801 13 0 12.3987 0 11.6568V4.94103C0 4.4524 0.271964 4.02471 0.678587 3.78969L7 0L13.3214 3.78972C13.728 4.02474 14 4.45242 14 4.94103Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--password {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='15' viewBox='0 0 11 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.625 5.16667C10.384 5.16667 11 5.76401 11 6.50001V13.1667C11 13.9027 10.384 14.5 9.625 14.5H1.375C0.616006 14.5 0 13.9027 0 13.1667V6.50001C0 5.76401 0.616006 5.16667 1.375 5.16667H2.0625V3.83334C2.0625 1.99267 3.60181 0.5 5.50001 0.5C7.39819 0.5 8.9375 1.99267 8.9375 3.83334V5.16667H9.625ZM5.50042 1.83458C4.3613 1.83458 3.43792 2.73001 3.43792 3.83458V5.16792H7.56292V3.83458C7.56292 2.73001 6.63954 1.83458 5.50042 1.83458Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--user {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 0C8.29489 0 9.75 1.45507 9.75 3.25C9.75 5.04489 8.29489 6.5 6.5 6.5C4.70507 6.5 3.25 5.04489 3.25 3.25C3.25 1.45507 4.70507 0 6.5 0ZM6.5 8.125C10.0899 8.125 13 9.58011 13 11.375V12C13 12.5523 12.5523 13 12 13H1C0.447715 13 0 12.5523 0 12V11.375C0 9.58011 2.91015 8.125 6.5 8.125Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--search {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 0C8.29489 0 9.75 1.45507 9.75 3.25C9.75 5.04489 8.29489 6.5 6.5 6.5C4.70507 6.5 3.25 5.04489 3.25 3.25C3.25 1.45507 4.70507 0 6.5 0ZM6.5 8.125C10.0899 8.125 13 9.58011 13 11.375V12C13 12.5523 12.5523 13 12 13H1C0.447715 13 0 12.5523 0 12V11.375C0 9.58011 2.91015 8.125 6.5 8.125Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--loc {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='15' viewBox='0 0 11 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.625 5.16667C10.384 5.16667 11 5.76401 11 6.50001V13.1667C11 13.9027 10.384 14.5 9.625 14.5H1.375C0.616006 14.5 0 13.9027 0 13.1667V6.50001C0 5.76401 0.616006 5.16667 1.375 5.16667H2.0625V3.83334C2.0625 1.99267 3.60181 0.5 5.50001 0.5C7.39819 0.5 8.9375 1.99267 8.9375 3.83334V5.16667H9.625ZM5.50042 1.83458C4.3613 1.83458 3.43792 2.73001 3.43792 3.83458V5.16792H7.56292V3.83458C7.56292 2.73001 6.63954 1.83458 5.50042 1.83458Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--eyes {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 3.20001C6.02086 3.20001 5.22727 4.00579 5.22727 4.99999C5.22727 5.99419 6.02086 6.79999 7 6.79999C7.97914 6.79999 8.77273 5.99419 8.77273 4.99999C8.77273 4.00579 7.97914 3.20001 7 3.20001ZM7 8C5.3685 8 4.04546 6.65659 4.04546 4.99999C4.04546 3.34341 5.3685 2 7 2C8.6315 2 9.95454 3.34341 9.95454 4.99999C9.95454 6.65659 8.6315 8 7 8ZM7 0.5C4.04428 0.5 1.52109 2.36662 0.5 4.99999C1.52109 7.6334 4.04428 9.5 7 9.5C9.95632 9.5 12.4789 7.6334 13.5 4.99999C12.4789 2.36662 9.95632 0.5 7 0.5Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--comment {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8 0H1.2C0.54 0 0.00599999 0.54 0.00599999 1.2L0.000671142 10.7919C0.000423594 11.2375 0.539147 11.4609 0.854225 11.1458L2.4 9.6H10.8C11.46 9.6 12 9.06 12 8.4V1.2C12 0.54 11.46 0 10.8 0ZM2.4 4.8C2.4 4.46863 2.66863 4.2 3 4.2H9C9.33137 4.2 9.6 4.46863 9.6 4.8C9.6 5.13137 9.33137 5.4 9 5.4H3C2.66863 5.4 2.4 5.13137 2.4 4.8ZM7.2 6.6C7.2 6.93137 6.93137 7.2 6.6 7.2H3C2.66863 7.2 2.4 6.93137 2.4 6.6C2.4 6.26863 2.66863 6 3 6H6.6C6.93137 6 7.2 6.26863 7.2 6.6ZM9.6 3C9.6 3.33137 9.33137 3.6 9 3.6H3C2.66863 3.6 2.4 3.33137 2.4 3C2.4 2.66863 2.66863 2.4 3 2.4H9C9.33137 2.4 9.6 2.66863 9.6 3Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--trash {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 1.33333C9.5 0.965144 9.20152 0.666667 8.83333 0.666667H7.25L6.90208 0.305864C6.71361 0.110411 6.45376 0 6.18224 0H3.81776C3.54624 0 3.28639 0.110411 3.09792 0.305864L2.75 0.666667H1.16667C0.798477 0.666667 0.5 0.965144 0.5 1.33333C0.5 1.70152 0.798477 2 1.16667 2H8.83333C9.20152 2 9.5 1.70152 9.5 1.33333ZM1.14286 10.6667C1.14286 11.4 1.72143 12 2.42857 12H7.57143C8.27857 12 8.85714 11.4 8.85714 10.6667V2.86667C8.85714 2.75621 8.7676 2.66667 8.65714 2.66667H1.34286C1.2324 2.66667 1.14286 2.75621 1.14286 2.86667V10.6667Z' fill='%23FF3939'/%3E%3C/svg%3E%0A");
    }
  }

  &--simple {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.39716 5.42099C7.64489 5.42099 7.78587 5.70427 7.63648 5.90189L7.16378 6.52722L6.75685 7.06261L0.916713 14.7821C0.802739 14.9336 0.567588 14.7919 0.648174 14.6204L0.67971 14.5532L3.36277 8.88206L3.99776 7.53603H0.473482C0.253391 7.53603 0.108221 7.30691 0.202219 7.10791L3.47835 0.171874C3.52791 0.066937 3.63356 0 3.74961 0H6.08646C6.30659 0 6.45176 0.229186 6.35769 0.428201L3.99776 5.42099H7.39716Z' fill='%2305C46B'/%3E%3C/svg%3E%0A");
    }
  }

  &--complex {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.39716 5.42099C7.64489 5.42099 7.78587 5.70427 7.63648 5.90189L7.16378 6.52722L6.75685 7.06261L0.916713 14.7821C0.802739 14.9336 0.567588 14.7919 0.648174 14.6204L0.67971 14.5532L3.36277 8.88206L3.99776 7.53603H0.473482C0.253391 7.53603 0.108221 7.30691 0.202219 7.10791L3.47835 0.171874C3.52791 0.066937 3.63356 0 3.74961 0H6.08646C6.30659 0 6.45176 0.229186 6.35769 0.428201L3.99776 5.42099H7.39716Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    }
  }

  &--super-complex {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.39716 5.42099C7.64489 5.42099 7.78587 5.70427 7.63648 5.90189L7.16378 6.52722L6.75685 7.06261L0.916713 14.7821C0.802739 14.9336 0.567588 14.7919 0.648174 14.6204L0.67971 14.5532L3.36277 8.88206L3.99776 7.53603H0.473482C0.253391 7.53603 0.108221 7.30691 0.202219 7.10791L3.47835 0.171874C3.52791 0.066937 3.63356 0 3.74961 0H6.08646C6.30659 0 6.45176 0.229186 6.35769 0.428201L3.99776 5.42099H7.39716Z' fill='%23F18F1C'/%3E%3C/svg%3E%0A");
    }
  }

  &--user-black {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5904 4.27612C13.4531 1.83829 11.4796 0 8.99998 0C6.52096 0 4.54715 1.83829 4.40945 4.27594C3.976 11.9533 14.0244 11.9619 13.5904 4.27612ZM6.14733 4.37216C6.34675 0.840991 11.6535 0.847291 11.8526 4.37216C12.1508 9.64634 5.84874 9.6518 6.14733 4.37216ZM8.99931 11.0767C3.97335 11.0767 1.01193 12.9009 0.658839 16.2263L0.5 18C0.746457 18 1.94056 18 2.24507 18C2.4078 16.2694 1.99723 12.8029 8.99931 12.8029C15.8871 12.8029 15.5778 15.9233 15.7549 18C16.0278 18 17.237 18 17.5 18C17.3299 16.2694 17.7065 11.0767 8.99931 11.0767Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }

  &--bell-black {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='17' viewBox='0 0 14 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 17C7.9625 17 8.75 16.2154 8.75 15.2564H5.25C5.25 16.2154 6.02875 17 7 17ZM12.25 11.7692V7.41026C12.25 4.73385 10.815 2.49333 8.3125 1.90051V1.30769C8.3125 0.584103 7.72625 0 7 0C6.27375 0 5.6875 0.584103 5.6875 1.30769V1.90051C3.17625 2.49333 1.75 4.72513 1.75 7.41026V11.7692L0 13.5128V14.3846H14V13.5128L12.25 11.7692Z' fill='%231E272E'/%3E%3C/svg%3E%0A");
    }
  }

  &--locale {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='globe-americas' class='svg-inline--fa fa-globe-americas fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512' style=' /* fill: %23f3cd29; */%0A'%3E%3Cpath fill='currentColor' d='M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z' style=' fill: %23f3cd29;%0A'%3E%3C/path%3E%3C/svg%3E");
    }
  }
}

.arrow {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    border-color: #000000;
    opacity: 0.25;
    top: 50%;
    transform: translateY(-50%);
  }

  &--right {
    // background-image: url("data:image/svg+xml,%3Csvg width='5' height='6' viewBox='0 0 5 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.25' d='M2.55514e-07 5.28917C2.89964e-07 5.90645 0.936869 6.22924 1.51784 5.81213L4.70628 3.52296C5.09791 3.24179 5.09791 2.75821 4.70628 2.47704L1.51784 0.187871C0.93687 -0.229241 -3.445e-08 0.0935505 0 0.710831L2.55514e-07 5.28917Z' fill='black'/%3E%3C/svg%3E%0A");
    &::before {
      right: 0;
      border: 5px solid;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }

  &--bottom {
    // background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.27324 0.5C0.403131 0.5 -0.0518705 1.53432 0.536081 2.17572L3.76285 5.69583C4.15918 6.1282 4.84082 6.1282 5.23715 5.69583L8.46392 2.17573C9.05187 1.53432 8.59687 0.5 7.72676 0.5H1.27324Z' fill='black'/%3E%3C/svg%3E%0A");
    &::before {
      right: 0;
      top: calc(50% + 2.5px);
      border: 5px solid;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
}

.double-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M1.10188 6C0.672305 6 0.442716 6.50596 0.725596 6.82925L3.12371 9.56996C3.32292 9.79762 3.67708 9.79762 3.87629 9.56996L6.27441 6.82925C6.55728 6.50596 6.3277 6 5.89812 6H1.10188Z' fill='%231E272E'/%3E%3Cpath opacity='0.5' d='M5.89812 4C6.3277 4 6.55728 3.49404 6.2744 3.17075L3.87629 0.430044C3.67708 0.20238 3.32292 0.20238 3.12371 0.430044L0.725595 3.17075C0.442715 3.49404 0.672305 4 1.10188 4L5.89812 4Z' fill='%231E272E'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;

  &--right {
    background-position: right center;
    padding-right: 15px;
  }
}

//Icon classes end

//Start default classes
.text {
  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}

.link {
  &--dark {
    color: #000;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }
}

.accent {
  &--color {
    color: var(--yellow-color);
  }

  &--bg {
    background-color: var(--yellow-color);
  }
}

.burger {
  position: relative;
  cursor: pointer;
  padding: 0;

  span {
    height: 3px;
    width: 24px;
    background: #000;
    display: block;
    margin-bottom: 4px;
    transition: all 0.3s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.active {
    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(1) {
      transform: rotate(45deg) translate(1px, 10px);
    }

    span:nth-child(3) {
      transform: rotate(-45deg) translate(0px, -9px);
    }
  }
}

.color {
  color: #000000;

  &--dark-gray {
    color: rgba(#000, 0.5);
  }

  &--green {
    color: #05c46b;
  }
}

.bullet {
  padding-left: 24px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000000;
  }

  &--yellow {
    &::before {
      background-color: #f3cd29;
    }
  }

  &--orange {
    &::before {
      background-color: #ffa502;
    }
  }

  &--blue {
    &::before {
      background-color: #1e90ff;
    }
  }

  &--green {
    &::before {
      background-color: #05c46b;
    }
  }

  &--purple {
    &::before {
      background-color: #b067dd;
    }
  }

  &--gray {
    &::before {
      background-color: #c4c4c4;
    }
  }

  &--red {
    &::before {
      background-color: #ff3939;
    }
  }

  &--dark-green {
    &::before {
      background-color: #20a63d;
    }
  }
}

.number {
  color: rgba(#1e272e, 0.5);
  background-color: rgba(#1e272e, 0.05);
  text-transform: uppercase;
  font-size: 0.85rem;
  line-height: 1;
  padding: 10px 16px;
  border-radius: 100px;
  display: inline-block;
}

.status {
  padding: 10px 16px;
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 0.85rem;
  line-height: 1;
  display: inline-block;

  &--green,
  &--completed {
    color: #05c46b;
    background-color: rgba(#05c46b, 0.06);
  }

  &--yellow,
  &--request {
    color: #f3cd29;
    background-color: rgba(#f3cd29, 0.06);
  }

  &--pricing {
    color: #ffa502;
    background-color: rgba(#ffa502, 0.06);
  }

  &--blue,
  &--approved {
    color: #1e90ff;
    background-color: rgba(#1e90ff, 0.06);
  }

  &--red,
  &--canceled {
    color: #ff3939;
    background-color: rgba(#ff3939, 0.06);
  }

  &--purple,
  &--revision,
  &--draft {
    color: #b067dd;
    background-color: rgba(#b067dd, 0.06);
  }
}

.user-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14px;

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 24px;
    object-fit: cover;
  }
}

.countdown {
  color: var(--yellow-color);
  background: rgba(#f3cd29, 0.06);
  border-radius: 4px;
  padding: 0.57rem 1.14rem;
}

//End default classes

//Start grid
.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.wrapper {
  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.display {
  &--flex {
    display: flex;
  }
}

.justify-content {
  &--space-around {
    justify-content: space-around;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }
}

.justify-self {
  &--end {
    justify-self: end;
  }

  &--center {
    justify-self: center;
  }
}

.align-items {
  &--center {
    align-items: center;
  }

  &--start {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }
}

.flex-direction {
  &--column {
    flex-direction: column;
  }
}

.title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.25;

  &--h2 {
    font-size: 1.45rem;
  }
}

.sub-title {
  color: rgba(#000, 0.5);
}

.content-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.14rem;
  align-items: center;

  strong {
    font-weight: 500;
    margin-left: 1.78rem;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1.14rem;
    }
  }
}

//End grid

//Start structural style
.app-wrapper {
  // overflow: hidden;
  padding-top: 88.8px;
}

.route-wrapper {
  min-height: calc(100vh - 208px);
}

.section {
  position: relative;

  &--default {
    // padding: 5rem 0;

    .section__top-bar {
      .btn--white {
        text-align: left;
      }

      .btn {
        min-width: 170px;

        &--white {
          min-width: 185px;
        }
      }

      .btn--big {
        margin-left: 24px;
      }

      .arrow::before {
        opacity: 1;
        right: 24px;
      }
    }
  }

  &__top-bar {
    padding: 3.42rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

//End structural style

//Start media
@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .section {
    &--default {
      .section {
        &__top-bar {
          padding: 2.6rem 0;
        }
      }
    }
  }

  html {
    font-size: 12px;
  }

  .app-wrapper {
    padding-top: 95px;
    //padding-top: 79px;
  }

  .route-wrapper {
    min-height: calc(100vh - 158px);
  }

  .hidden-on-mobile {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .form {
    &__row {
      grid-template-columns: 1fr;
    }
  }
}

@media (min-width: 768px) {
  .hidden-on-desktop {
    display: none !important;
  }
}

////End media
#plupload_plupload{
  display: none;
}
