.progress-bar {
  border: 1px solid var(--yellow-color);
  background-color: transparent;
  position: absolute;
  border-radius: 20px;
  text-align: center;
  display: table;
  width: 90%;
  max-width: 230px;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: var(--yellow-color);
  }

  span {
    position: relative;
    display: block;
    padding: 5px;
    font-size: 12px;
    font-weight: normal !important;
  }
}