//Start input and textarea style
.input,
.textarea {
  width: 100%;
  font-size: 1rem;
  -webkit-appearance: none !important;

  &.icon {
    &::before {
      left: 20px;
    }

    input,
    textarea,
    select {
      -webkit-appearance: none;
      padding-left: 50px;
      outline: none;
    }
  }
}

input,
textarea {
  border: 2px solid rgba(#000000, 0.05);
  border-radius: 8px;
  padding: 1rem 1rem 0.9rem 1rem;
  width: 100%;
  transition: border-color 0.3s ease-in-out;
  -webkit-appearance: none;
  outline: none;
  resize: vertical;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus:required:invalid {
    border-color: rgba(red, 0.5);
  }

  // &:required:valid {
  //   // border-color: rgba(#f3cd29, 0.5);
  // }

  &::-webkit-internal-autofill-selected {
    background-color: #ffffff;
  }
}
//End input and textarea style
