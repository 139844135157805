.pagination {
  display: flex;
  align-items: center;
  margin: 16px 0;

  li {
    margin: 0 1.14rem;

    a {
      display: block;
      background-color: rgba(30, 39, 46, 0.05);
      border-radius: 4px;
      padding: 0.57rem;
      text-decoration: none;
      color: rgba(30, 39, 46, 0.5);
      font-weight: 600;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgba(30, 39, 46, 0.08);
      }

      &.active {
        color: var(--yellow-color);
      }
    }
  }
}
