html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ol,
ul {
  list-style: none; }

img,
video {
  max-width: 100%; }

img {
  border-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: ''; }

[hidden] {
  display: none !important; }

[disabled] {
  cursor: not-allowed; }

:focus:not(:focus-visible) {
  outline: none; }
