.job {
  &__state-wrapper {
    align-items: stretch;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    transition: margin-left 0.3s ease;
  }

  &__state {
    border: 2px solid rgba(#000, 0.1);
    border-radius: 8px;
    padding: 1.57rem;
    transition: border-color 0.3s ease, color 0.3s ease;
    // cursor: pointer;
    flex: 1;
    min-width: 150px;
    margin: 1.72rem 15px;
    color: rgba(#1e272e, 0.5);
    font-weight: 40;

    .title {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0.2rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      border-color: #f3cd29;
      color: #1e272e;
    }
  }
}

//Start media
@media (max-width: 992px) {
  .job {
    &__state {
      margin: 1.72rem 7.5px;
    }
  }
}

@media (max-width: 768px) {
  .job {
    &__state-wrapper {
      &--active-2 {
        //padding-right: 60px !important;
        .job__state:first-child {
          margin-left: -60px;
        }
      }

      &--active-3 {
        //padding-right: 135px !important;
        .job__state:first-child {
          margin-left: -135px;
        }
      }

      &--active-4 {
        padding-right: 0px !important;
        .job__state:first-child {
          margin-left: -210px;
        }
      }
    }
    &__state {
      width: 150px;
    }
  }
}

@media (max-width: 576px) {
  .job {
    &__state-wrapper {
      &--active-1 {
        //padding-right: 165px !important;
        .job__state:first-child {
          //margin-left: -165px;
        }
      }

      &--active-2 {
        //padding-right: 270px !important;
        .job__state {
          transform: translateX(95%);
        }

        .job__state:first-child {
          margin-left: -270px;
        }
      }

      &--active-3 {
        //padding-right: 270px !important;
        .job__state:first-child {
          margin-left: -270px;
        }
      }

      &--active-4 {
        //padding-right: 435px !important;
        .job__state:first-child {
          margin-left: -435px;
        }
      }
    }
  }
}
//End  media
