.job__requested {
  .job__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

//Start media
@media (max-width: 768px) {
  .job__requested {
    .job__body {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
}
//End media
