//Start check style
.checkbox {
	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;

		&:checked {
			& + label {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	label {
		position: relative;
		cursor: pointer;
		padding-left: 30px;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 14px;
			height: 14px;
			border-radius: 4px;
			border: 2px solid rgba(#000, 0.05);
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 4px;
			top: 6px;
			width: 10px;
			height: 7px;
			background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 1L3.6875 6L1.5 3.72727' stroke='%23F3CD29' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
	}
}
//End check style
