.preloader {
  width: 100%;
  display: block;
  height: 80px;
  opacity: 0.3;
  margin-top: 60px;

  &--full {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
