.table {
  &--job-types {
    .table__header {
      grid-template-columns: minmax(150px, 4fr) minmax(150px, 4fr) minmax(70px, 3fr) minmax(90px, 1fr);
    }

    .table__row {
      grid-template-columns: minmax(150px, 4fr) minmax(150px, 4fr) minmax(70px, 3fr) minmax(90px, 1fr);
      align-items: center;
    }
    
    .user-link {
      cursor: pointer;
    }
  }
}

@media (max-width: 992px) {
  .table {
    &--job-types {
      .table__header {
        display: none;
      }

      .table__row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        grid-template-columns: minmax(100px, 3fr) 1fr;
        grid-template-rows: 45px 50px minmax(0px, 1fr);
        grid-template-areas:
                'status action'
                'name name'
                'description description';
      }

      .table__cell {
        &:first-child {
          grid-area: name;
          font-weight: bold;
        }

        &:nth-child(2) {
          grid-area: description;
        }

        &:nth-child(3) {
          grid-area: status;
        }

        &:nth-child(4) {
          grid-area: action;
          justify-self: end;
        }
      }
    }
  }
}