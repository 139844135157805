.job__completed {
  .job__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon--download {
      padding: 0.9rem 1.71rem 0.9rem 3.57rem;

      &::before {
        left: 1.71rem;
      }
    }
  }
}

//Start media
@media (max-width: 768px) {
  .job__completed {
    .job__heading {
      .icon--download {
        position: fixed;
        min-width: auto;
        width: 48px;
        height: 48px;
        padding: 12px;
        overflow: hidden;
        display: block;
        border-radius: 50%;
        right: 32px;
        bottom: 32px;
        text-indent: 48px;
        white-space: nowrap;

        &::before {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

//End media
