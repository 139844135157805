.job__create-new {
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__result {
      margin-top: -2.25rem;
    }
  }
}

.form {
  &--select-job-type {
    margin-bottom: 3.42rem;
  }

  .checkbox {
    label {
      color: rgba(#000, 0.5);
      display: block;
    }

    input {
      &:checked + label {
        color: #000;
      }
    }
  }
}

.selected-options {
  li {
    margin-top: 1.7rem;
    padding-left: 34px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0px;
      width: 14px;
      height: 14px;
      border-radius: 4px;
      border: 2px solid rgba(#000, 0.05);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 4px;
      top: 8px;
      width: 10px;
      height: 7px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 1L3.6875 6L1.5 3.72727' stroke='%23F3CD29' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

//Start media
@media (max-width: 768px) {
  .job__create-new {
    .form {
      grid-template-columns: 1fr;
    }
  }
}
//End media
