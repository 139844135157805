.badge {
  position: relative;

  &--icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
  }

  &--pointer {
    cursor: pointer;
  }

  &--yellow {
    background-color: var(--yellow-color);
  }

  &--gray {
    background-color: rgba(#1e272e, 0.05);
  }

  &--label {
    .badge__counter {
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }

    .label {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .label {
        opacity: 0.7;
      }
    }
  }

  &__counter {
    color: #ffffff;
    background: #ff3939;
    font-size: 0.45rem;
    width: 14px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 14px;
  }

  .icon {
    // top: 50%;
    left: calc(50% - 8px);
  }
}
