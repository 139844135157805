.section {
  &.job {
    .job {
      &__heading {
        margin-bottom: 5.14rem;

        .title {
          font-size: 1.71rem;
          margin-bottom: 0.71rem;
        }

        &--two-column {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 30px;
        }
      }

      &__body {
        margin: 0 -15px 6rem;
      }

      &__detail {
        flex: 1;
        padding: 0 15px;

        .bullet {
          margin-bottom: 1.14rem;
        }
      }

      &__images {
        flex: 1;
        padding: 0 15px;
      }

      &__detail-item {
        margin-bottom: 2.28rem;

        .bullet {
          display: flex;
          align-items: center;
        }

        > div {
          &:first-child {
            text-transform: uppercase;
            font-size: 0.86rem;
            margin-bottom: 1.14rem;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

//Start media
@media (max-width: 768px) {
  .section {
    &.job {
      .job {
        &__images {
          margin-top: 2.28rem;
        }

        &__heading {
          &--two-column {
            grid-template-columns: 1fr;

            .info-block {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .section {
    &.job {
      .job {
        //
      }
    }
  }
}
//End media
