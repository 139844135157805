.job {
  &__supplier-approved {
    .job__images {
      & > div {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .countdown {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .job {
    &__supplier-approved {
      .countdown {
        display: block;
        align-self: center;
        justify-self: end;
      }

      .job__heading.job__heading--two-column {
        grid-template-columns: 3fr 1fr;
      }
    }
  }
}