.drop-down {
	position: relative;

	&--active {
		.drop-down {
			&__body {
				opacity: 1;
				pointer-events: initial;
			}
		}
	}

	&--disabled {
		.drop-down__body {
			opacity: 0;
			pointer-events: none;
		}
	}

	&__label {
		cursor: pointer;
	}

	&--select {
		.drop-down__body {
			&--center-bottom {
				margin-top: -4px;
			}

			&--left-bottom {
				margin-top: -4px;
			}
		}
	}

	&__body {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s ease-in-out;
		box-shadow: 1px 2px 52px rgba(0, 0, 0, 0.05);
		position: absolute;
		z-index: 2;
		min-width: 170px;
		background-color: #ffffff;

		&--center-bottom {
			transform: translate(-50%, 8px);
			left: 50%;
			margin-top: 8px;
		}

		&--left-bottom {
			right: 0;
			margin-top: 8px;
		}
	}

	ul {
		li {
			a {
				text-decoration: none;
				color: inherit;
				display: block;
				padding: 12px 24px;
			}
		}
	}

	&__select-list {
		li {
			padding: 12px 24px;
			cursor: pointer;
			transition: background-color 0.3s ease-in-out;

			&.bullet {
				padding-left: 50px;

				&::before {
					left: 24px;
				}
			}

			&:hover {
				background-color: rgba(#c4c4c4, 0.15);
			}
		}
	}

	&__item {
		&--edit {
			padding: 0 !important;
		}
	}
}
