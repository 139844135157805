.job__approved {
  .job__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .job__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 70px;
  }
}

//Start media
@media (max-width: 768px) {
  .job__approved {
    .job__body {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
}
//End media
