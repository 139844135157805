.notifications-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
}

.notifications {
    position: absolute;
    right: 5px;
    top: -8px;
}

.notification {
    padding: 15px 16px;
    background: #FFFFFF;
    box-shadow: 1px 2px 48px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
    align-items: center;
    max-width: 250px;
    cursor: pointer;

    + .notification {
        margin-top: 10px;
    }

    &__icon {
        height: 40px;
        width: 40px;
        border-radius: 100px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 40px;
        max-width: 40px;

        svg {
            max-width: 20px;
        }

        &--info {
            background: rgba(243, 205, 41, 0.06);
        }

        &--error {
            background: rgba(255, 57, 57, 0.06);

            svg {
                margin-top: -3px;
            }
        }
    }

    span {
        font-size: 0.9rem;
        line-height: 1.3rem;
        font-weight: bold;
    }
}