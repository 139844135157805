section.job .job__requested {
  .job__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .icon--download {
    padding-left: 50px;

    &::before {
      left: 24px;
    }
  }

  &--pricing {
    .job__body {
      grid-template-columns: 1fr;
    }
  }
}

.select-job-item-button {
  display: flex;
  border: 2px solid #05c46b;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  height: 110px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }

  &--yellow {
    border-color: #f3cd29;
  }

  &--yellow {
    border-color: #f3cd29;
  }

  &--orange {
    border-color: #f18f1c;
  }
}

//Start media
@media (max-width: 768px) {
  section.job {
    .job__requested {
      .job__body {
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
}
//End media
