.authentication {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(74px, 150px) 1fr;
  padding: 0 15px;
  margin-top: -88.8px;

  .locale-switcher-wrapper {
    position: absolute;
    right: 25px;
    top: 14px;
    font-size: 14px;
  }

  .notifications-wrapper {
    right: 10px;
    top: 20px;
  }

  p,
  a {
    font-weight: 500;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(217.48deg, #f3cd29 -1.01%, #ffde51 102.44%);
  }

  &__logo {
    align-self: end;
    justify-self: center;
    grid-column-start: 2;

    svg {
      line-height: 0;
    }
  }

  &__content {
    align-self: center;
    justify-self: center;
    grid-column-start: 2;
    margin: 24px 0;
    width: 100%;
    max-width: 330px;
  }

  .form {
    ::placeholder {
      color: #000;
      font-weight: 600;
    }

    input {
      font-weight: 600;
    }

    label {
      font-weight: 500;
    }

    .btn {
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .authentication {
    grid-template-rows: minmax(74px, 150px) 1fr;
    grid-template-columns: 1fr;

    &::before {
      display: none;
    }

    &__content {
      grid-column-start: 1;
    }

    &__logo {
      grid-column-start: 1;
    }
  }
}

@media (max-width: 768px) {
  .authentication {
    margin-top: -79px;
  }
}

@media (max-width: 500px) {
  .authentication {
    .locale-switcher-wrapper {
      top: 10px;
      right: 15px;
    }
  }
}
