//Start input and textarea style
.locale-switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__title {
    position: relative;
    padding: 5px 6px;
    cursor: pointer;

    svg {
      margin-left: 5px;
    }
  }

  ul {
    position: absolute;
    top: 0;
    min-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 6px 13px 6px;
    text-align: center;
    box-shadow: 1px 2px 48px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  li {
    font-size: 13px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--yellow-color);
    }
  }
}
