.upload-box {
  &__input {
    input {
      display: none;
    }

    label {
      border: 2px dashed rgba(#000, 0.1);
      border-radius: 8px;
      width: 100%;
      padding: 5.35rem;
      display: block;
      text-align: center;
      position: relative;

      svg {
        display: block;
        margin: 0 auto 1.15rem;
      }

      span {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.42;
        display: block;
      }

      .preview img {
        position: absolute;
        width: 90%;
        height: 90%;
        top: 5%;
        left: 5%;
        transition: opacity 0.3s ease 0.2s;
      }
    }
  }

  &--uploading {
    label {
      position: relative;
      z-index: 1;

      .preview img {
        opacity: 0.2;
      }
    }
  }
}

.preview {
  max-width: 100%;

  img {
    object-fit: cover;
  }
}
//Start media
@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .upload-box {
    &__input {
      label {
        padding: 2.28rem;
      }
    }
  }
}
//End media
