//Start button style
.btn {
  font-size: 1rem;
  text-decoration: none;
  color: #000000;
  line-height: 1.4;
  transition: background-color 0.3s ease;
  font-weight: 500;
  position: relative;
  overflow: hidden;

  &--select {
    border: 2px solid rgba(#000000, 0.05);
    border-radius: 8px;
    padding: 1rem 1rem 0.9rem 1rem;
    width: 100%;
    transition: border-color 0.3s ease-in-out;
    color: rgba(#000, 0.5);
    text-align: left;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49951 15.2999C4.75016 15.2999 1.69951 12.2494 1.69951 8.49995C1.69951 4.75064 4.75016 1.7 8.49951 1.7C12.2489 1.7 15.2995 4.75064 15.2995 8.49995C15.2995 12.2494 12.2489 15.2999 8.49951 15.2999ZM8.49951 0C3.80496 0 -0.000488281 3.80544 -0.000488281 8.49995C-0.000488281 13.1946 3.80496 16.9999 8.49951 16.9999C13.1941 16.9999 16.9995 13.1946 16.9995 8.49995C16.9995 3.80544 13.1941 0 8.49951 0ZM9.34951 5.1C9.34951 4.63056 8.96895 4.25 8.49951 4.25C8.03007 4.25 7.64951 4.63056 7.64951 5.1V7.64995H5.09951C4.63007 7.64995 4.24951 8.03051 4.24951 8.49995C4.24951 8.9694 4.63007 9.34995 5.09951 9.34995H7.64951V11.9C7.64951 12.3694 8.03007 12.75 8.49951 12.75C8.96895 12.75 9.34951 12.3694 9.34951 11.9V9.34995H11.8995C12.369 9.34995 12.7495 8.9694 12.7495 8.49995C12.7495 8.03051 12.369 7.64995 11.8995 7.64995H9.34951V5.1Z' fill='%23F3CD29'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;

    // &:hover {
    // 	border-color: var(--yellow-color);
    // }
  }

  &--big {
    display: block;
    padding: 1.15rem;
    font-weight: 600;
    border-radius: 8px;
  }

  &--elips {
    border-radius: 31px;
    padding: 0.85rem 1.7rem;
  }

  &--filter {
    color: rgba(#1e272e, 0.5);
    text-transform: uppercase;
    font-weight: 600;
  }

  &--yellow {
    background-color: var(--yellow-color);

    &:hover {
      background-color: rgba(#f3cd29, 0.8);
    }
  }

  &__progress-bar {
    position: absolute;
    width: 0;
    display: block;
    height: 100%;
    background: rgba(215, 178, 21, 0.46);
    top: 0;
    left: 0;
    transition: all .5s ease-in-out;
  }

  &--gray {
    background-color: rgba(#1e272e, 0.05);

    &:hover {
      background-color: rgba(#1e272e, 0.1);
    }
  }

  &--yellow-transparent {
    border: 2px solid #f3cd29;
    background-color: transparent;

    &:hover {
      background-color: var(--yellow-color);
    }
  }

  &--white {
    display: block;
    padding: 1.1rem 24px;
    border: 2px solid rgba(#000, 0.05);
    border-radius: 8px;
    background-color: #ffffff;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &:disabled {
    color: inherit;
  }
}

a.btn {
  text-align: center;
}

.drop-down--active {
  .btn {
    //
  }
}

.btn-group {
  display: flex;
  margin: 3rem -0.57rem 3rem;

  &--payment {
    justify-content: stretch;
    align-items: stretch;

    & > .btn {
      flex: 1;
    }

    .drop-down__label {
      height: 100%;

      .btn {
        height: 100%;
        margin: 0;
        padding: 0 1.64rem;

        &::before {
          right: calc(50% - 5px);
          opacity: 1;
        }
      }
    }
  }

  .btn {
    margin: 0 0.57rem;
    padding: 1.14rem 2.28rem;
  }
}

//End button style

//Start media
@media (max-width: 576px) {
  .btn-group {
    flex-direction: column;

    .btn {
      margin-bottom: 1.71rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

//End media
