.table {
  &__header {
    display: grid;
    padding: 14px;
    border-radius: 8px;
    background-color: rgba(#1e272e, 0.05);
    color: rgba(#1e272e, 0.5);
    text-transform: uppercase;
    font-weight: 600;
  }

  &__body {
    padding: 16px 8px;
  }

  &__row {
    padding: 16px 0;
    display: grid;
  }

  &--jobs {
    .table {
      &__footer {
        .btn {
          margin: 16px 0;
          padding: 1rem 2.25rem;
        }
      }

      &__row {
        // grid-template-columns: minmax(80px, 2fr) 7fr 3fr 3fr minmax(110px, 1fr) 1fr;
        grid-template-columns: 7fr 3fr 3fr minmax(110px, 1fr) 1fr;
        align-items: center;

        .table__cell:nth-child(2) {
          margin-left: 0 //-15px;
        }

        .table__cell:nth-child(1) {
          padding-left: 0; //7px;
        }

        .table__cell:nth-child(3) {
          padding-right: 15px;
        }
      }

      &__header {
        // grid-template-columns: minmax(80px, 2fr) 7fr 3fr 3fr minmax(110px, 1fr) 1fr;
        grid-template-columns: 7fr 3fr 3fr minmax(110px, 1fr) 1fr;

        .btn {
          padding-left: 0;
        }
      }
    }
  }

  &--profiles {
    .table {
      &__header {
        grid-template-columns: minmax(150px, 4fr) minmax(150px, 4fr) minmax(105px, 3fr) minmax(105px, 3fr) minmax(90px, 1fr);

        .btn {
          padding-left: 0;
        }
      }

      &__footer {
        .btn {
          margin: 16px 0;
          padding: 1rem 2.25rem;
        }
      }

      &__row {
        grid-template-columns: minmax(150px, 4fr) minmax(150px, 4fr) minmax(105px, 3fr) minmax(105px, 3fr) minmax(90px, 1fr);
        align-items: center;

        .table__cell:last-child {
          justify-self: end;
        }
      }
    }
  }

  &--billing {
    .table {
      &__header {
        grid-template-columns: minmax(130px, 1fr) 7fr minmax(75px, 2fr);
        padding: 14px 24px;

        .table__cell {
          &:last-child {
            justify-self: end;
          }
        }

        .btn {
          padding-left: 0;
        }
      }

      &__body {
        padding: 24px;

        .table__cell {
          &:last-child {
            justify-self: end;
            padding-right: 15px;
          }
        }
      }

      &__footer {
        padding: 48px 24px;
        border-top: 2px solid rgba(#000, 0.05);

        & > div {
          &:last-child {
            padding-right: 15px;
          }
        }
      }

      &__row {
        padding: 24px 0;
        grid-template-columns: minmax(130px, 1fr) 7fr minmax(75px, 2fr);
        align-items: center;
      }
    }
  }

  &--job-types, &--jobs {
    .table__row {
      padding: 4px 0;
    }

    .table__cell {
      &:first-child {
        div, a {
          display: block;
          padding: 16px 0;
        }
      }
    }
  }
}

//Start media
@media (max-width: 992px) {
  .table {
    &--job-types {
      .table {
        &__row {
          padding: 24px 0;

          &:first-child {
            padding-top: 0;
          }
        }
      }
    }

    &--jobs {
      .table {
        &__header {
          display: none;
        }

        &__row {
          padding: 24px 0;
          border-bottom: 1px solid rgba(#000, 0.1);

          &:first-child {
            padding-top: 0;
          }

          .table__cell {
            // &:nth-child(1) {
            // 	grid-area: number;
            // }
            &:nth-child(1) {
              grid-area: name;
            }

            &:nth-child(2) {
              grid-area: status;
            }

            &:nth-child(3) {
              grid-area: date;
            }

            &:nth-child(4) {
              grid-area: action;
              justify-self: end;
            }

            &:nth-child(5) {
              grid-area: more-actions;
            }
          }

          grid-template-columns: minmax(160px, 1fr) 1fr;
          grid-template-rows: 30px minmax(40px, 1fr) 40px;

          grid-template-areas: 'status more-actions' 'name name' 'date action';
        }
      }
    }

    &--profiles {
      .table {
        &__header {
          display: none;
        }

        &__row {
          padding: 24px 0;
          border-bottom: 1px solid rgba(#000, 0.1);
          align-items: start;

          &:first-child {
            padding-top: 0;
          }

          .table__cell {
            &:nth-child(1) {
              grid-area: name;
              margin-bottom: 2rem;
            }

            &:nth-child(2) {
              grid-area: email;
            }

            &:nth-child(3) {
              grid-area: amount;
              justify-self: center;
              display: none;
            }

            &:nth-child(4) {
              grid-area: date;
              justify-self: end;
            }

            &:nth-child(5) {
              grid-area: action;
              justify-self: end;
            }
          }

          grid-template-columns: minmax(90px, 1fr) minmax(50px, 1fr) minmax(95px, 1fr);

          grid-template-areas: 'name name action' 'email amount date';
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .table {
    &--billing {
      .table {
        &__header {
          display: none;
        }

        &__body {
          padding: 0;
        }

        &__footer {
          border: none;
          padding: 48px 0;
        }

        &__row {
          padding: 24px 0;
          border-bottom: 1px solid rgba(#000, 0.1);

          &:first-child {
            padding-top: 0;
          }

          .table__cell {
            &:nth-child(1) {
              grid-area: date;
            }

            &:nth-child(2) {
              grid-area: name;
            }

            &:nth-child(3) {
              grid-area: amount;
              justify-self: end;
            }
          }

          grid-template-columns: minmax(100px, 1fr) minmax(70px, 90px);
          grid-template-rows: 30px minmax(40px, 1fr);

          grid-template-areas: 'date date' 'name amount';
        }
      }
    }
  }
}

@media (max-width: 576px) {
}

//End media
