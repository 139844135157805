.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000, 0.5);
  z-index: 3;
  overflow-y: scroll;

  .modal-window {
    padding: 3.42rem;
    background-color: #ffffff;
    border-radius: 8px;
    margin: 3.42rem 0;
    position: absolute;
    width: 770px;
    max-width: calc(100% - 30px);
    top: 7%;
    left: 50%;
    transform: translateX(-50%);

    &__header {
      font-size: 1.71rem;
      margin-bottom: 3.42rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: rgba(#000, 0.5);
        font-size: 1.28rem;
      }
    }

    &__body {
      .btn--big {
        padding: 1.14rem 3.42rem;
      }

      &--single-file-upload {
        .upload-box {
          margin-bottom: 3.42rem;
        }

        .btn:last-child {
          margin-left: 1.14rem;
        }
      }

      &--add-user {
        .btn {
          margin-top: 0.85rem;

          &:last-child {
            margin-left: 1.14rem;
          }
        }
      }

      &--scrollable {
        height: 484px;
        padding-right: 24px;
        overflow-y: scroll;
        overflow-x: hidden;
        max-width: 100%;
      }

      &--img {
        text-align: center;

        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .btn--close {
      width: 32px;
      height: 32px;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      right: -32px;
      top: -32px;
      line-height: 0;
      min-width: auto !important;

      svg {
        transition: transform 0.3s;
      }

      &:hover {
        svg {
          transform: scale(1.2);
        }
      }
    }
  }
}

//Start media
@media (max-width: 992px) {
  .modal-wrapper {
    .modal-window {
      width: 500px;
    }
  }
}

@media (max-width: 768px) {
  .modal-window {
    padding: 2.28rem;
    margin: 2.28rem;

    .btn--close {
      top: -48px;
      right: -16px;
    }
  }
}

@media (max-width: 600px) {
  .modal-wrapper {
    .modal-window {
      width: 100%;
      padding: 2.3rem;

      &__header {
        margin-bottom: 2.3rem;
        font-size: 1.4rem;

        &--rows-on-mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        span {
          font-size: 1.1rem;
        }
      }

      .btn--close {
        right: 0px;
        top: -44px;
      }
    }
  }
}

@media (max-width: 576px) {
  .modal-window {
    padding: 1.71rem;
    margin: 1.71rem;

    &__header {
      margin-bottom: 2.28rem;
    }

    &__body {
      &--scrollable {
        height: 358px;
        padding-right: 18px;
      }
    }
  }
}
//End media
