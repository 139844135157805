.job__requested-pricing {
  .job {
    &__body {
      display: grid;
      grid-template-areas: "detail images";
      grid-template-columns: 1fr 1fr;
      grid-gap: 70px;
    }

    &__detail {
      grid-area: detail;
    }

    &__images {
      grid-area: images;
    }
  }
}

//Start media
@media (max-width: 768px) {
  section.job .job__requested-pricing {
    .job {
      &__body {
        grid-template-columns: 1fr;
        grid-gap: 0;
        grid-template-areas:
          "images"
          "detail";
      }
      &__detail {
        margin-top: 3.74rem;
      }

      &__heading {
        margin-bottom: 1.14rem;
      }
    }
  }
}

@media (max-width: 576px) {
  section.job .job__requested-pricing {
    .job {
      &__detail {
        .btn-group {
          flex-direction: row;
          .btn {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
//End media
