// .grid-wrapper {
//   display: grid;
//   height: 70vh;
//   grid-template-columns: 1fr 1fr 1fr;
//   grid-template-rows: 1fr 1fr 1fr;
//   // justify-items: center;
//   // align-items: center;
// }

// .grid-item {
//   border: 1px solid #000;
//   font-size: 42px;

//   &-1 {
//     background-color: red;
//     grid-row: 1/3;
//     grid-column: 1/2;
//     align-self: center;
//     justify-self: center;
//   }
//   &-2 {
//     background-color: green;
//   }
//   &-3 {
//     background-color: blue;
//     grid-column: 3/4;
//     grid-row: 3/3;
//   }
// }

.section {
  &.jobs {
    padding-bottom: 33px;

    .table {
      &--jobs {
        h3 {
          font-weight: normal;
          text-align: center;
        }

        .btn--elips {
          font-size: 0.85rem;
          margin: 0 auto;
        }

        .table__row {
          a:not(.btn) {
            color: #000000;
            text-decoration: none;

            &:hover {
              color: rgba(#000, 0.8);
            }
          }

          a.btn--elips {
            margin-right: 0;
            padding: 0.85rem 0 0.85rem 0.25rem;
          }

          .drop-down {
            &__label {
              text-align: right;
            }

            .icon {
              display: inline-block;
              padding-bottom: 12px;
            }
          }

          .drop-down ul li:first-child {
            padding: 0;
          }
        }
      }
    }
  }
}

.no-title {
  opacity: .4;
}

//Start media
@media (max-width: 992px) {
  .section {
    &.jobs {
      .table {
        &--jobs {
          .btn--elips {
            margin-right: 0;
          }

          .table__row {
            .table__cell:nth-child(2) {
              a {
                font-size: 16px;
                padding: 14px 0 6px 0;
              }
            }

            .table__cell:nth-child(4) {
              font-size: 12px;
            }

            a.btn--elips {
              padding: 0.85rem;
            }

            .drop-down {
              .icon {
                padding-bottom: 12px;
                background: rgba(#1e272e, 0.05);
                height: 28px;
                width: 28px;
                border-radius: 50%;
                padding-bottom: 16px;

                &::before {
                  right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section {
    &.jobs {
      margin-bottom: 16px;

      .section {
        &__top-bar {
          .btn--yellow {
            position: fixed;
            min-width: auto;
            width: 48px;
            height: 48px;
            overflow: hidden;
            display: block;
            border-radius: 50%;
            right: 32px;
            bottom: 32px;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.25192 8.53638C8.25192 8.41065 8.35385 8.30873 8.47957 8.30873H12.6846C13.4111 8.30873 14 7.71978 14 6.99329C14 6.26679 13.4111 5.67785 12.6846 5.67785H8.47904C8.35361 5.67785 8.25192 5.57617 8.25192 5.45074V1.25865C8.25192 0.563517 7.6884 0 6.99327 0C6.29813 0 5.73462 0.563518 5.73462 1.25865V5.45074C5.73462 5.57617 5.63293 5.67785 5.5075 5.67785H1.31543C0.58894 5.67785 0 6.26679 0 6.99329C0 7.71978 0.588941 8.30873 1.31544 8.30873H5.50696C5.63269 8.30873 5.73462 8.41065 5.73462 8.53638V12.7413C5.73462 13.4365 6.29813 14 6.99327 14C7.6884 14 8.25192 13.4365 8.25192 12.7413V8.53638Z' fill='black'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            text-indent: 48px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

//End media
