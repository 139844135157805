.footer {
	padding: 48px 0;
	border-top: 1px solid rgba(#000, 0.1);
}

@media (max-width: 768px) {
	.footer {
		padding: 28px 0;
	}
}
