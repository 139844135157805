.info-block {
  color: rgba(#000, 0.5);
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 0;
  padding: 2.28rem 2.28rem 2.28rem 4.56rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: -2.28rem;
  }

  &--yellow {
    background-color: rgba(#f3cd29, 0.06);
    color: #f3cd29;
    svg {
      display: none;
    }
  }

  span {
    padding-left: 1.14rem;
  }
}
