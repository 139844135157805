//Start input and textarea style
select {
  width: 100%;

  &.icon {
    &::before {
      left: 20px;
    }

    input,
    textarea {
      padding-left: 50px;
    }
  }
}

select {
  border: 2px solid rgba(#000000, 0.05);
  border-radius: 8px;
  padding: 1rem 1rem 0.9rem 1rem;
  width: 100%;
  transition: all 0.3s ease-in-out;
  outline: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus:required:invalid {
    border-color: rgba(red, 0.5);
  }

  &:required:valid {
    border-color: rgba(#f3cd29, 0.5);
  }

  &::-webkit-internal-autofill-selected {
    background-color: #ffffff;
  }
}
//End input and textarea style
