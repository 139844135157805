//Start input and textarea style
.lang-switcher {
  display: flex;

  li {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;

    &.active {
      color: var(--yellow-color);
    }
  }
}
